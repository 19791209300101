@import './variables.less';

.ant-btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    font-family: "Poppins", serif;
    font-size: 14px;
    font-weight: 600;
    height: 40px;
    &.append-icon {
        .anticon {
            font-size: 24px;
        }
    }
    &.ant-btn-circle {
        border-radius: 50%;
        width: 40px;
    }
    &.ant-btn-sm {
        font-size: 12px;
        height: 35px;
        &.ant-btn-circle {
            width: 35px;
        }
    }
    &.ant-btn-lg {
        font-size: 16px;
        height: 45px;
        &.ant-btn-circle {
            width: 45px;
        }
    }
    &.fixed-width {
        width: 180px;
        &.small {
            width: 140px;
        }
        @media (max-width: 768px) {
            width: fit-content;
            &.small {
                width: fit-content;
            }
        }
    }
}

.btn-blue {
    &.ant-btn-primary {
        background-color: @color-blue;
        border-color: @color-blue;
        color: white;
        &:hover, &:focus {
            background-color: @color-light-blue;
            border-color: @color-light-blue;
            color: white;
        }
    }
    border-color: @color-blue;
    color: @color-blue;
    &:hover, &:focus {
        border-color: @color-light-blue;
        color: @color-light-blue;
    }
}

.btn-white {
    &.ant-btn-primary {
        background-color: @color-white;
        border-color: @color-white;
        color: @color-grey;
        &:hover, &:focus {
            background-color: @color-white;
            border-color: @color-white;
            color: @color-grey;
        }
    }
    border-color: @color-white;
    color: @color-white;
    &:hover, &:focus {
        border-color: @color-white;
        color: @color-white;
    }
}

.btn-green {
    &.ant-btn-primary {
        background-color: @color-green;
        border-color: @color-green;
        color: white;
        &:hover, &:focus {
            background-color: @color-light-green;
            border-color: @color-light-green;
            color: white;
        }
    }
    border-color: @color-green;
    color: @color-green;
    &:hover, &:focus {
        border-color: @color-light-green;
        color: @color-light-green;
    }
}

.btn-red {
    &.ant-btn-primary {
        background-color: @color-red;
        border-color: @color-red;
        color: white;
        &:hover, &:focus {
            background-color: @color-light-red;
            border-color: @color-light-red;
            color: white;
        }
    }
    border-color: @color-red;
    color: @color-red;
    &:hover, &:focus {
        border-color: @color-light-red;
        color: @color-light-red;
    }
}