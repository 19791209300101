@import './variables';

.theme-layout {
  .active-border {
    border-color: transparent;
  }

  .color-default {
    color: @color-default-default-main !important;
    svg {
      fill: @color-default-default-main !important;
    }
  }
  .color-grey {
    color: @color-default-grey !important;
    svg {
      fill: @color-default-grey !important;
    }
  }

  &.dark {
    .ant-card {
      background-color: @theme-sidebar-background-dark;
    }
    .color-grey {
      color: @color-dark-grey !important;
      svg {
        fill: @color-dark-grey !important;
      }
    }
    .quill {
      .ql-toolbar.ql-snow {
        color: @dark-color-grey;
        svg {
          fill: @dark-color-grey;
        }
      }
      .ql-container.ql-snow {
        color: @dark-color-grey;
        .ql-tooltip {
          z-index: 999;
          background-color: @theme-sidebar-background-dark;
          color: @dark-color-grey;
          box-shadow: 0 0 5px @theme-sidebar-background-dark;
          * {
            background-color: @theme-sidebar-background-dark;
            color: @dark-color-grey;
          }
        }
      }
    }
  }

  &.default.color-default {
    .active-border {
      &.active {
        border-color: @color-default-default-main;
      }
    }
    .color-default {
      color: @color-default-default-main !important;
      svg {
        fill: @color-default-default-main !important;
      }
    }
  }

  &.dark.color-default {
    .active-border {
      &.active {
        border-color: @color-dark-default-main;
      }
    }
    .color-default {
      color: @color-dark-default-main !important;
      svg {
        fill: @color-dark-default-main !important;
      }
    }
  }

  &.default.color-happy {
    .active-border {
      &.active {
        border-color: @color-default-happy-main;
      }
    }
    .color-default {
      color: @color-default-happy-main !important;
      svg {
        fill: @color-default-happy-main !important;
      }
    }
  }

  &.dark.color-happy {
    .active-border {
      &.active {
        border-color: @color-dark-happy-main;
      }
    }
    .color-default {
      color: @color-dark-happy-main !important;
      svg {
        fill: @color-dark-happy-main !important;
      }
    }
  }

  &.default.color-warm {
    .active-border {
      &.active {
        border-color: @color-default-warm-main;
      }
    }
    .color-default {
      color: @color-default-warm-main !important;
      svg {
        fill: @color-default-warm-main !important;
      }
    }
  }

  &.dark.color-warm {
    .active-border {
      &.active {
        border-color: @color-dark-warm-main;
      }
    }
    .color-default {
      color: @color-dark-warm-main !important;
      svg {
        fill: @color-dark-warm-main !important;
      }
    }
  }

  &.default.color-ocean {
    .active-border {
      &.active {
        border-color: @color-default-ocean-main;
      }
    }
    .color-default {
      color: @color-default-ocean-main !important;
      svg {
        fill: @color-default-ocean-main !important;
      }
    }
  }

  &.dark.color-ocean {
    .active-border {
      &.active {
        border-color: @color-dark-ocean-main;
      }
    }
    .color-default {
      color: @color-dark-ocean-main !important;
      svg {
        fill: @color-dark-ocean-main !important;
      }
    }
  }

  &.default.color-forest {
    .active-border {
      &.active {
        border-color: @color-default-forest-main;
      }
    }
    .color-default {
      color: @color-default-forest-main !important;
      svg {
        fill: @color-default-forest-main !important;
      }
    }
  }

  &.dark.color-forest {
    .active-border {
      &.active {
        border-color: @color-dark-forest-main;
      }
    }
    .color-default {
      color: @color-dark-forest-main !important;
      svg {
        fill: @color-dark-forest-main !important;
      }
    }
  }

  &.default.color-neutral {
    .active-border {
      &.active {
        border-color: @color-default-neutral-main;
      }
    }
    .color-default {
      color: @color-default-neutral-main !important;
      svg {
        fill: @color-default-neutral-main !important;
      }
    }
  }

  &.dark.color-neutral {
    .active-border {
      &.active {
        border-color: @color-dark-neutral-main;
      }
    }
    .color-default {
      color: @color-dark-neutral-main !important;
      svg {
        fill: @color-dark-neutral-main !important;
      }
    }
  }
}

.ubot-dropdown-default {
  position: fixed;
  .ant-dropdown-menu {
    .ant-dropdown-menu-item-disabled {
      background-color: @theme-background-light;
      opacity: .5 !important;
    }
  }
  &.color-default {
    .ant-dropdown-menu-item-selected {
      background-color: @color-default-default-main-light-background;
      color: @color-default-default-main;
    }
    .color-default {
      color: @color-default-default-main !important;
      svg {
        fill: @color-default-default-main !important;
      }
    }
  }
  &.color-happy {
    .ant-dropdown-menu-item-selected {
      background-color: @color-default-happy-main-light-background;
      color: @color-default-happy-main;
    }
    .color-default {
      color: @color-default-happy-main !important;
      svg {
        fill: @color-default-happy-main !important;
      }
    }
  }
  &.color-warm {
    .ant-dropdown-menu-item-selected {
      background-color: @color-default-warm-main-light-background;
      color: @color-default-warm-main;
    }
    .color-default {
      color: @color-default-warm-main !important;
      svg {
        fill: @color-default-warm-main !important;
      }
    }
  }
  &.color-ocean {
    .ant-dropdown-menu-item-selected {
      background-color: @color-default-ocean-main-light-background;
      color: @color-default-ocean-main;
    }
    .color-default {
      color: @color-default-ocean-main !important;
      svg {
        fill: @color-default-ocean-main !important;
      }
    }
  }
  &.color-forest {
    .ant-dropdown-menu-item-selected {
      background-color: @color-default-forest-main-light-background;
      color: @color-default-forest-main;
    }
    .color-default {
      color: @color-default-forest-main !important;
      svg {
        fill: @color-default-forest-main !important;
      }
    }
  }
  &.color-neutral {
    .ant-dropdown-menu-item-selected {
      background-color: @color-default-neutral-main-light-background;
      color: @color-default-neutral-main;
    }
    .color-default {
      color: @color-default-neutral-main !important;
      svg {
        fill: @color-default-neutral-main !important;
      }
    }
  }
}

.ubot-dropdown-dark, .ubot-submenu-dark, .ubot-drawer-dark {
  svg {
    fill: @dark-color-light-grey1 !important;
  }
  .ant-dropdown-menu, .ant-menu {
    border-right-color: transparent;
    background-color: @dark-color-dark-grey2;
    .ant-dropdown-menu-item , .ant-menu-item-selected{
      color: @dark-color-light-grey1;
      &:hover {
        background-color: @dark-color-dark-grey1;
      }
    }
    .ant-dropdown-menu-submenu, .ant-menu-submenu {
      .ant-dropdown-menu-submenu-title, .ant-menu-submenu-title {
        color: @dark-color-light-grey1;
        .ant-dropdown-menu-submenu-arrow-icon, .ant-menu-submenu-arrow {
          color: @dark-color-light-grey1;
        }
      }
      &:hover {
        .ant-dropdown-menu-submenu-title, .ant-menu-submenu-title {
          background-color: @dark-color-dark-grey1;
        }
      }
      &.ant-dropdown-menu-submenu-disabled, &.ant-menu-submenu-disabled {
        .ant-dropdown-menu-submenu-title, .ant-menu-submenu-title {
          color: @dark-color-light-grey1;
          background-color: @theme-background-dark;
          .ant-dropdown-menu-submenu-arrow-icon, .ant-menu-submenu-arrow {
            color: @dark-color-light-grey1;
            background-color: @theme-background-dark;
          }
        }
        &:hover {
          .ant-dropdown-menu-submenu-title, .ant-menu-submenu-title {
            background-color: @dark-color-dark-grey1;
          }
        }
      }
    }
    .ant-dropdown-menu-item-disabled, .ant-menu-item-disabled {
      background-color: @theme-background-dark;
      opacity: .5 !important;
    }
    .ant-dropdown-menu-item-divider, .ant-menu-item-divider {
      background-color: #e8e8e8;
    }
  }
  .color-grey {
    color: @dark-color-light-grey1 !important;
  }
  &.color-default {
    .ant-dropdown-menu-item-selected, .ant-menu-item-selected {
      background-color: @dark-color-dark-grey1;
      color: @color-dark-default-main;
    }
    .color-default {
      color: @color-dark-default-main !important;
      svg {
        fill: @color-dark-default-main !important;
      }
    }
  }
  &.color-happy {
    .ant-dropdown-menu-item-selected, .ant-menu-item-selected {
      background-color: @dark-color-dark-grey1;
      color: @color-dark-happy-main;
    }
    .color-default {
      color: @color-dark-happy-main !important;
      svg {
        fill: @color-dark-happy-main !important;
      }
    }
  }
  &.color-warm {
    .ant-dropdown-menu-item-selected, .ant-menu-item-selected {
      background-color: @dark-color-dark-grey1;
      color: @color-dark-warm-main;
    }
    .color-default {
      color: @color-dark-warm-main !important;
      svg {
        fill: @color-dark-warm-main !important;
      }
    }
  }
  &.color-ocean {
    .ant-dropdown-menu-item-selected, .ant-menu-item-selected {
      background-color: @dark-color-dark-grey1;
      color: @color-dark-ocean-main;
    }
    .color-default {
      color: @color-dark-ocean-main !important;
      svg {
        fill: @color-dark-ocean-main !important;
      }
    }
  }
  &.color-forest {
    .ant-dropdown-menu-item-selected, .ant-menu-item-selected {
      background-color: @dark-color-dark-grey1;
      color: @color-dark-forest-main;
    }
    .color-default {
      color: @color-dark-forest-main !important;
      svg {
        fill: @color-dark-forest-main !important;
      }
    }
  }
  &.color-neutral {
    .ant-dropdown-menu-item-selected, .ant-menu-item-selected {
      background-color: @dark-color-dark-grey1;
      color: @dark-color-light-grey1;
    }
    .color-default {
      color: @dark-color-light-grey1 !important;
      svg {
        fill: @dark-color-light-grey1 !important;
      }
    }
  }
}

.ant-picker-dropdown {
  &.dark {
    color: #646464;

    .ant-picker-panel-container {
      background: #292929;

      .ant-picker-panel {
        border: 1px solid #323232;
      }

      .ant-picker-header {
        border-bottom: 1px solid #323232;
        >button {
          color: rgba(255, 255, 255, 0.25);
        }
        .ant-picker-header-view {
          button {
            color: rgba(255, 255, 255, 0.85);
          }
        }
      }

      .ant-picker-body {
        .ant-picker-content {
          th {
            color: rgba(255, 255, 255, 0.85);
          }
        }
        .ant-picker-cell {
          color: rgba(255, 255, 255, 0.25);
          &:hover {
            .ant-picker-cell-inner {
              background: #252525 !important;
            }
          }
        }
        .ant-picker-cell-in-view {
          color: rgba(255, 255, 255, 0.85);
        }
      }
    }

    .ant-picker-time-panel {
      .ant-picker-time-panel-column {
        &:not(:first-child) {
          border-left: 1px solid #323232;
        }
        .ant-picker-time-panel-cell {
          .ant-picker-time-panel-cell-inner {
            color: rgba(255, 255, 255, 0.85);
            &:hover {
              background: #4a4a4a;
            }
          }
          &.ant-picker-time-panel-cell-selected {
            .ant-picker-time-panel-cell-inner {
              background: #414f60;
            }
          }
        }
      }
    }

    .ant-picker-footer {
      border-top: 1px solid #323232;
      .ant-picker-today-btn {
        &.ant-picker-today-btn-disabled {
          color: rgba(255, 255, 255, .25);
        }
      }
    }

    .ant-btn-primary[disabled] {
      background: #2f2f2f;
      color: rgba(255, 255, 255, .25);
      border-color: rgba(255, 255, 255, .25);
    }
  }
}