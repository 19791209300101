@import '~antd/dist/antd.less';
@import './assets/style/variables.less';
@import './assets/style/common.less';
@import './assets/style/button.less';
@import './assets/style/theme.less';

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: normal;
  src: url("./assets/font/Poppins/Poppins-Regular.ttf");
}

@font-face {
  font-family: 'Open sans regular';
  font-style: normal;
  font-weight: normal;
  src: url('./assets/font/Open_sans/OpenSans-Regular.ttf');
}

body {
  font-family: "Open sans regular", serif;
  font-size: 16px;
  color: @color-light-grey !important;
  background-color: #F8F8F8;
}

body * {
  transition: .3s all;
  text-decoration: none !important;
}

body a {
  color: @color-blue;
}
@primary-color: #1D99F1;@link-color: #1D99F1;